<script>
  import MessageList from "./MessageList.svelte";
  import { getTime } from "./Tool.svelte";

  export let name;

  // 状态
  let state = {
    visible: false
  }

  // 元数据
  let data = {
    input: "",
    avatar: "https://sdn.geekzu.org/avatar/d22eb460ecab37fcd7205e6a3c55c228?s=300&r=G&d=robohash",
    messages: [
      {
        content: "Hi there, I'm Paul and you?",
        time: "11:47"
      },
      {
        type: "own",
        content: "my order has no id",
        time: "11:50"
      },
      {
        content: "sorry, the system was errored. Let me help you.",
        time: "11:50"
      }
    ]
  }

  // 切换展示
  function toggleBody(){
    state.visible = !state.visible;
  }

  // 发送消息
  function send(){
    if(!data.input) return;

    data.messages = [...data.messages, {
      type: "own",
      content: data.input,
      time: getTime()
    }];

    data.input = "";
  }

  // 快捷键发送
  function sendByEnter(ev){
    ev.preventDefault();
    if(ev.keyCode === 13) send();
  }
</script>

<main class={"chat-body" + (state.visible ? " active" : "")}>
  <div class="chat-title">
    <h1>Paul Jizzy</h1>
    <h2>Support</h2>
    <figure class="avatar">
      <img src={data.avatar} alt="Avatar"/>
    </figure>
  </div>
  <MessageList data={data.messages} avatar={data.avatar}/>
  <div class="chat-reply">
    <textarea class="reply-input" placeholder="Type message..." rows="2"
      bind:value={data.input}
      on:keyup={sendByEnter}
    />
    <button type="submit" class="reply-submit" on:click={send}>Send</button>
  </div>
</main>
<div class="chat-toggle" on:click={toggleBody}/>
