<script>
  import { afterUpdate } from 'svelte';
  import MessageItem from "./MessageItem.svelte";

  let list;

  afterUpdate(() => {
    list.scrollTo({
      top: list.scrollHeight,
      behavior: "smooth"
    });
  });

  export let data, avatar;
</script>

<div class="chat-list" bind:this={list}>
  {#each data as item}
    <MessageItem data={item} avatar={avatar}/>
  {/each}
</div>
