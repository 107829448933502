<script context="module">
  export function getTime() {
    let _date = new Date();

    return addZero(_date.getHours()) + ":" + addZero(_date.getMinutes());
  }

  export function addZero(num) {
    return num >= 10 ? num : "0" + num;
  }
</script>