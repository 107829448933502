<script>
  export let data, avatar;

  function messageClassname() {
    switch(data.type){
      case "own": return "message-item personal";
      default: return "message-item";
    }
  }

  let classname = messageClassname();
</script>

<div class={classname}>
  {#if !data.type || data.type !== "own"}
    <figure class="avatar">
      <img src={avatar} alt="Avatar">
    </figure>
  {/if}
  {data.content}<time class="timestamp">{data.time}</time>
</div>
